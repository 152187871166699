import axios from 'axios';

const headers = {
    //    'X-App-Name': 'test12.2.mysimulator.ru'
    'X-App-Name': window.location.hostname
}

if (localStorage.getItem("token")) {
    headers.Authorization = `Token ${localStorage.getItem("token")}`;
}
let url = '//api.msmtr.net/api/'
if (window.location.hostname == "learn.uni380.com") {
    url = '//api.uni380.com/api/'
}
export default
    axios.create({
        baseURL: url,
        //baseURL: 'http://127.0.0.1:8000/api/',

        headers: headers
    });

