var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user && _vm.user.sim_info)?_c('div',{staticClass:"sidebar",attrs:{"id":"SideBar"}},[_c('div',{staticClass:"sidebar__container p-0 pt-md-5 pb-4 pl-md-4 pr-md-2"},[(_vm.simInfo.logo)?_c('Logo',{attrs:{"logo":_vm.simInfo.logo}}):_vm._e(),(_vm.routeName === 'Notifications' || _vm.routeName === 'Theory')?_c('BackButton'):_vm._e(),(
        (_vm.routeName !== 'Notifications' &&
          _vm.routeName !== 'Theory' &&
          _vm.routeName !== 'Home') ||
        !(_vm.user.sim_info.onboarding_complete || _vm.simInfo.onboarding_skip)
      )?_c('Description',{attrs:{"name":_vm.simInfo.name,"description":_vm.simInfo.description}}):_vm._e(),(
        _vm.simInfo.price != 0 &&
        !_vm.user.sim_info.simulator_paid &&
        _vm.routeName !== 'MakeUser' &&
        _vm.routeName !== 'Home' &&
        _vm.routeName !== 'Notifications' &&
        _vm.routeName !== 'Theory'
      )?_c('PayButton',{attrs:{"price":_vm.simInfo.price}}):_vm._e(),(
        _vm.routeName === 'Home' &&
        (_vm.user.sim_info.onboarding_complete || _vm.simInfo.onboarding_skip)
      )?_c('PagePreviews'):_vm._e(),(
        _vm.routeName === 'Home' &&
        (_vm.user.sim_info.onboarding_complete || _vm.simInfo.onboarding_skip)
      )?_c('CourseItems'):_vm._e(),(_vm.routeName !== 'MakeUser')?_c('Footer',{attrs:{"onboarding":_vm.user.sim_info.onboarding_complete || _vm.simInfo.onboarding_skip,"paid":_vm.user.sim_info.simulator_paid}}):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }