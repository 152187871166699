<template>
    <svg :width="size" :height="size" :viewBox="`0 0 30 30`" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#3C579E"/>
        <path d="M13.1858 24.375V15.9023H10.1624V12.8836H13.1858V10.0945C13.1858 7.28203 14.8452 5.625 17.3553 5.625C18.5577 5.625 19.5374 5.75391 19.8397 5.79375V8.95313H17.8194C16.4553 8.95313 16.1999 9.63047 16.1999 10.5797V12.8836H19.6967L19.2022 15.9047H16.1999V24.375H13.1858Z" fill="white"/>
    </svg>
</template>

<script>
    export default {
        name: "Facebook",
        props:{
            size: {
                type: Number,
                default: 30,
            },
        }
    }
</script>

<style scoped>

</style>